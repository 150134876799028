import styled from 'styled-components';
import NoImageDisplay from 'registration_form_builder/Image/drag_and_drop.svg';
import PropTypes from 'prop-types';

const ImageContainer = styled.img`
  width: 100%;
  height: auto;
`;

const DropContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EAEAEA;
`;

const DropIcon = styled.img`
  width: 150px;
  height: auto;
  flex: 2;
`;

const StaticImage = ({ imageSrc }) => {
  if (imageSrc === '') {
    return (
      <DropContainer>
        <DropIcon src={NoImageDisplay} />
      </DropContainer>
    );
  }
  return !!imageSrc && (
    <ImageContainer src={imageSrc} />
  );
};

StaticImage.propTypes = {
  imageSrc: PropTypes.string,
};

StaticImage.defaultProps = {
  imageSrc: '',
};

export default StaticImage;
