import PQueue from 'p-queue';
import xtraClient from 'helpers/xtraApollo/client';

export const client = xtraClient();

const maxConcurrent = 1;
export const queue = new PQueue({ concurrency: maxConcurrent });

const currentInQueue = {
  state: 0,
  effects: (dispatch) => ({
    initForm(orderStatus) {
      dispatch.form.initForm({ orderStatus, queue, client });
    },
    fetchLandingPage() {
      dispatch.form.fetchLandingPage({ queue, client });
    },
    fetchTicketingPage() {
      return dispatch.form.fetchTicketingPage({ queue, client });
    },
    fetchFormPage() {
      dispatch.form.fetchFormPage({ queue, client });
    },
    fetchThankYouPage() {
      return dispatch.thankYouPage.fetchThankYouPage({ queue, client });
    },
    createNewOrder(regformAccessKey) {
      dispatch.orderAccessKey.createNewOrder({
        regformAccessKey,
        queue,
        client,
      });
    },
    formSubmit() {
      dispatch.form.formSubmit();
    },
    orderLockQuotaMutation() {
      dispatch.orderAccessKey.orderLockQuotaMutation({ queue, client });
    },
    orderInitiatePaymentMutation() {
      dispatch.orderAccessKey.orderInitiatePaymentMutation({ queue, client });
    },
    confirmOfflinePayment() {
      return dispatch.orderAccessKey.orderOfflinePaymentConfirmMutation({ queue, client });
    },
    orderInitiatePaymentV4Mutation() {
      dispatch.orderAccessKey.orderInitiatePaymentV4Mutation({ queue, client });
    },
    initiateWeChatPayPolling() {
      return dispatch.paymentPage.initiateWeChatPayPolling({ queue, client });
    },
    orderFinalize() {
      dispatch.orderAccessKey.orderFinalize({ queue, client });
    },
    orderCancel() {
      dispatch.orderAccessKey.orderCancel({ client });
    },
    updateComponentAttrMutation(props) {
      dispatch.form.updateComponentAttrMutation({
        ...props, queue, client,
      });
    },
    copyAttendee(props) {
      dispatch.attendee.copyAttendee({ ...props, queue, client });
    },
    addAttendeeMutation(noOfTicket) {
      return dispatch.attendee.addAttendee({ queue, client, noOfTicket });
    },
    addTicketMutation({ attendeeIds, ticketList }) {
      return dispatch.ticket.addTicketMutation({
        queue,
        client,
        attendeeIds,
        ticketList,
      });
    },
    getOrderQuotation(discountCode) {
      return dispatch.appliedDiscountCode.getOrderQuotation({
        queue,
        client,
        discountCode,
      });
    },
    applyDiscountCode() {
      return dispatch.appliedDiscountCode.applyDiscountCode({
        queue,
        client,
      });
    },
    removeAllAttendeeFromOrder() {
      dispatch.attendee.removeAllAttendeeFromOrder({
        queue,
        client,
      });
    },
    rsvpOrderLookupOrCreate({ rsvpInvitationToken, rsvpGoing }) {
      dispatch.orderAccessKey.rsvpOrderLookupOrCreate({
        rsvpInvitationToken,
        rsvpGoing,
        queue,
        client,
      });
    },
    fetchRsvpNoThankYouPage(formId) {
      dispatch.rsvpNoThankYouPage.fetchRsvpNoThankYouPage({ formId, queue, client });
    },
    rsvpOrderFinalize() {
      dispatch.orderAccessKey.rsvpOrderFinalize({ queue, client });
    },
    processToPayment() {
      dispatch.orderAccessKey.processToPayment({ queue, client });
    },
    validateAccessCode() {
      return dispatch.accessCode.validateAccessCode({ queue, client });
    },
  }),
};

export default currentInQueue;
