export const sendGa = (eventName?: string) => {
  const title = eventName ? `${document.title} - ${eventName}` : document.title;
  if (typeof window !== 'undefined') {
    setTimeout(() => {
      window.ga('eventx.send', {
        hitType: 'pageview',
        title,
      });
      window.ga('clientGA.send', {
        hitType: 'pageview',
        title,
      });
    }, 0);
  }
};

export default { sendGa };
