import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const en = i18n.getFixedT('en');

interface CountryCodeOption {
  value: string;
  label: string;
}

const sortCountryCode = (
  options: CountryCodeOption[],
): CountryCodeOption[] => options.sort((a, b) => en(`country.${a.value}`).localeCompare(en(`country.${b.value}`)));

const useCountryCodeOptions = (): CountryCodeOption[] => {
  const { t } = useTranslation();
  const countryCodes = useSelector((s) => s.countryCodes, shallowEqual) as string[];
  const locale = useSelector((s) => (s.locale));
  const chinaFriendlyModeEnabled = useSelector((s) => s.featureGuard.chinaFriendlyModeEnabled);

  const mappedCountryCodes = useMemo<CountryCodeOption[]>(
    () => (countryCodes || []).map(
      (country) => {
        if (chinaFriendlyModeEnabled && ['hk', 'mo', 'tw'].includes(country)) {
          return ({
            value: country,
            label: t(`country.${country}_cn`, locale.code),
          });
        }
        return ({
          value: country,
          label: t(`country.${country}`, locale.code),
        });
      },
    ),
    [countryCodes, locale, chinaFriendlyModeEnabled],
  );

  return sortCountryCode(mappedCountryCodes);
};

export default useCountryCodeOptions;
