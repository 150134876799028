/* eslint-disable no-underscore-dangle */
import FormValueType from 'schema/formValueType';
import _cloneDeep from 'lodash/cloneDeep';
import _uniqBy from 'lodash/uniqBy';
import { client, queue as networkQueue } from 'registration_form/Model/currentInQueue';
import { DocumentNode } from 'graphql';
import createBatchAttendeeMutation from './createBatchAttendeeMutation';

const DEFAULT_ATTENDEE_UPDATE_DURATION = 1000;

interface AttendeeUpdatePayload {
  attendeeId: string;
  fieldElementId: string;
  valueType: FormValueType;
  callback?: (success: boolean, responseData?: unknown) => void;
  value: unknown;
}

interface UpdateResponse {
  [valueName: string]: {
    success: boolean;
    errors: unknown[];
    __typename: string;
  }
}

interface InternalAttendeeUpdatePayload extends AttendeeUpdatePayload {
  updateTime: number;
}

interface _AttendeeUpdateQueue {
  setAccessKey: (orderAccessKey: string) => void;
  add: (payload: AttendeeUpdatePayload) => void;
  addBatch: (batchPayload: AttendeeUpdatePayload[]) => void;
  empty: () => boolean;
  flush: () => Promise<void>;
  isExecutingUpdate: () => boolean;
}

/**
 * Queue for attendee update payload
 */
let _queue: InternalAttendeeUpdatePayload[] = [];

/**
 * Order access key of the current active order
 */
let _orderAccessKey: string;

/**
 * Variable storing the NodeJS Timeout used for setTimeout
 */
let _timeout: NodeJS.Timeout = null;

/**
 * Flag to indicate whether an update is being performed on the queue.
 */
let _isExecutingUpdate = false;

/**
   *
   * Sets the order access key for the attendee update queue.
   *
   * @param orderAccessKey Order access key of the current registration.
   */
function setAccessKey(orderAccessKey: string): void {
  _orderAccessKey = orderAccessKey;
}

/**
   * Internal method to reset all timers being used to perform the attendee update.
   */
function _resetTimeout(): void {
  if (_timeout) {
    clearTimeout(_timeout);
    _timeout = null;
  }
}

/**
   * Internal method to execute the attendee update job.
   */
async function _execute(): Promise<void> {
  const sortedTasksToExecute = _cloneDeep(_queue)
    .sort((a, b) => b.updateTime - a.updateTime);
  _isExecutingUpdate = true;
  _queue.splice(0, _queue.length);
  if (!_orderAccessKey) {
    throw new Error('Order access key has not been initialized properly!');
  }
  _resetTimeout();
  const uniqTasksToExecute: AttendeeUpdatePayload[] = _uniqBy(
    sortedTasksToExecute,
    ({ fieldElementId, attendeeId }) => `${attendeeId}::${fieldElementId}`,
  );
  const executeAttendeeUpdateMutation: DocumentNode = createBatchAttendeeMutation({
    updates: uniqTasksToExecute,
  });
  const mutationVariables = uniqTasksToExecute.reduce((acc, {
    attendeeId,
    fieldElementId,
    value,
  }, index) => {
    acc[`input${index}`] = {
      attendeeId,
      fieldElementId,
      value,
      orderAccessKey: _orderAccessKey,
    };
    return acc;
  }, {});
  try {
    const updateResponse = await networkQueue.add(() => client.mutate({
      mutation: executeAttendeeUpdateMutation,
      variables: {
        ...mutationVariables,
      },
    }));
    const { data: updateResponseData } = updateResponse;
    uniqTasksToExecute.forEach(({ callback }, index) => {
      const responseValue = (updateResponseData as UpdateResponse)[`setValue${index}`];
      if (responseValue.success) {
        if (callback) {
          callback(true, responseValue);
        }
      } else {
        console.error(responseValue.errors);
      }
    });
  } catch (e) {
    console.error(e);
    const currentTime = Date.now();
    _queue = _queue.concat(
      uniqTasksToExecute.map((task) => ({
        ...task,
        updateTime: currentTime,
      })),
    );
  }
  _isExecutingUpdate = false;
  if (_queue.length) {
    // eslint-disable-next-line no-use-before-define
    _setExecutionTimer();
  }
}

/**
   *
   * Sets the timer to execute an attendee update job.
   *
   * @param duration Duration before an execution will be performed (in milliseconds)
   */
function _setExecutionTimer(duration = DEFAULT_ATTENDEE_UPDATE_DURATION): void {
  if (_timeout === null) {
    _timeout = setTimeout(_execute, duration);
  }
}

/**
   *
   * Adds a job to the queue of updating attendees.
   *
   * @param payload Attendee form field to be updated
   */
function add(payload: AttendeeUpdatePayload): void {
  _queue.push({
    ...payload,
    updateTime: Date.now(),
  });
  _setExecutionTimer();
}

/**
   *
   * Batch adds job to the queue of updating attendees.
   *
   * @param batchPayload List of attendee form fields to be updated
   */
function addBatch(batchPayload: AttendeeUpdatePayload[]): void {
  const currentTime = Date.now();
  _queue = _queue.concat(batchPayload.map((payload) => ({
    ...payload,
    updateTime: currentTime,
  })));
  _setExecutionTimer();
}

/**
   * Clears all the remaining jobs and executes all of them at once.
   *
   * Ideally used when submitting the form.
   */
async function flush(): Promise<void> {
  _resetTimeout();
  if (_queue.length) {
    await _execute();
  }
}

/**
   * Flag to indicate whether there is currently any update jobs being queued.
   */
function empty(): boolean {
  return !!_queue.length;
}

/**
   * Flag to indicate whether a network call is being performed to the attendee update API.
   */
function isExecutingUpdate(): boolean {
  return _isExecutingUpdate;
}

/**
 * Queue for attendee update.
 *
 * Constructs a batched GraphQL mutation for setting an attendee's mainForm fields.
 *
 * Queue is implemented as a singleton so that there will only be a single source of truth
 * for all updates being performed.
 */
const AttendeeUpdateQueue = {
  setAccessKey,
  add,
  addBatch,
  empty,
  flush,
  isExecutingUpdate,
};

export default AttendeeUpdateQueue;
