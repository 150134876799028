import RegformFieldElement from 'schema/fieldElement';

const shouldDisplayInputTitle = (typeName: RegformFieldElement): boolean => [
  RegformFieldElement.FirstName,
  RegformFieldElement.LastName,
  RegformFieldElement.Salutation,
  RegformFieldElement.PrimaryEmail,
  RegformFieldElement.SecondaryEmail,
  RegformFieldElement.Phone,
  RegformFieldElement.Country,
  RegformFieldElement.City,
  RegformFieldElement.Company,
  RegformFieldElement.Title,
  RegformFieldElement.Industry,
  RegformFieldElement.TextQuestion,
  RegformFieldElement.StringQuestion,
  RegformFieldElement.RadioMcQuestion,
  RegformFieldElement.CheckboxMcQuestion,
  RegformFieldElement.DropdownMcQuestion,
  RegformFieldElement.FileUploadQuestion,
].includes(typeName);

export default shouldDisplayInputTitle;
