
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextContainer = styled.div`
  padding: 16px 24px;

  p:empty::before {
    content: ' ';
    white-space: pre;
  }
`;

const StaticText = ({ text }) => (
  <TextContainer>{ReactHtmlParser(text)}</TextContainer>
);

StaticText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default StaticText;
