
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { XtraFlexbox, XtraHintText } from 'components';
import { useTranslation } from 'react-i18next';

const StyledXtraHintText = styled(XtraHintText)`
  color: #A2AAB3;
  margin-left: 32px;
  margin-right: 16px;
`;

const QuestionText = styled(XtraHintText)`
  padding: 0 8px;
  line-height: 20px;
  margin-bottom: 8px;
  background-color: #F4F8FF;
  border: 1px solid #5C8DF3;
  border-radius: 2px;
`;

const LogicalRelatedQuestion = ({ choiceId, logicalConditions, locale }) => {
  let conditions = logicalConditions.getIn([choiceId]) || List();
  conditions = conditions.toJS();
  const { t } = useTranslation();

  if (conditions.length) {
    return (
      <XtraFlexbox alignItems="normal">
        <StyledXtraHintText>
          { t('regform_v3_builder.logical_question.setting.related_question', { locale: locale.emsMapping }) }
        </StyledXtraHintText>
        <XtraFlexbox direction="column" alignItems="normal">
          {
            conditions.map(
              ({ labelText, conditionId }) => (
                <QuestionText key={conditionId}>{labelText}</QuestionText>
              ),
            )
          }
        </XtraFlexbox>
      </XtraFlexbox>
    );
  }
  return null;
};

LogicalRelatedQuestion.propTypes = {
  choiceId: PropTypes.string.isRequired,
  logicalConditions: PropTypes.shape().isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

const mapState = (state) => ({
  logicalConditions: state.logicalConditions,
  locale: state.locale,
});

export default connect(mapState)(LogicalRelatedQuestion);
