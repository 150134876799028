import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import logoPng from './images/eventx-logo.png';
import logoWhitePng from './images/eventx-logo-white.png';

const FooterContainer = styled.div`
  padding: 20px 0;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 85%;
  text-align: center;
  opacity: 0.3;
`;

const LogoImage = styled.img`
  width: 100px;
`;

const StyledLink = styled.a`
  color: ${({ colorMode }) => (colorMode === 'black' ? '#000' : 'white')};

  &:hover, &:focus {
    color: ${({ colorMode }) => (colorMode === 'black' ? '#000' : 'white')};
    text-decoration: none;
  }

  span.freemium-link {
    text-decoration: underline;
  }
`;

const Footer = ({
  colorMode,
  locale,
}) => {
  const { t } = useTranslation();
  const signupUrlWithLocale = 'https://eventx.io/?pk_campaign=power-by-eventx&pk_source=reg-form&pk_medium=direct';
  return (
    <FooterContainer>
      <p>
        <a href={signupUrlWithLocale} target="_BLANK" rel="noopener noreferrer">
          { colorMode === 'black' ? <LogoImage src={logoPng} /> : <LogoImage src={logoWhitePng} /> }
        </a>
      </p>
      <p>
        <StyledLink href={signupUrlWithLocale} colorMode={colorMode} target="_BLANK" rel="noopener noreferrer">
          {t('regsvp.footer_msg_1', { locale: locale.emsMapping })}
          <span className="freemium-link">
            {t('regsvp.footer_msg_2', { locale: locale.emsMapping })}
          </span>
        </StyledLink>
      </p>
    </FooterContainer>
  );
};

const mapState = (state) => ({
  locale: state.locale,
});

Footer.propTypes = {
  colorMode: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

export default connect(mapState, null)(Footer);
