
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XtraFlexbox, XtraCheckbox } from 'components';
import LogicalQuestionEditor from './LogicalQuestionEditor';
import LogicalRelatedQuestion from './LogicalRelatedQuestion';

const StyledXtraFlexbox = styled(XtraFlexbox)`
  min-height: 48px;
  position: relative;
`;

const Container = styled.div`
  padding: 0 32px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  line-height: 40px;
  position: relative;
`;

const OptionContainer = styled.div`
  width: 100%;
`;

const LogicalCheckBoxGroup = ({
  choices,
  themeColor,
  targetObjectId,
  currentEditLogicalChoiceId,
  fetchLogicalConditions,
}) => (
  <Container>
    {choices
    && choices.map((eachOption) => (
      <StyledXtraFlexbox
        alignItems="normal"
        key={eachOption.value}
      >
        {
          eachOption.value === currentEditLogicalChoiceId ? (
            <LogicalQuestionEditor
              value={eachOption.value}
              label={eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}
            />
          ) : (
            <OptionContainer
              onClick={() => {
                fetchLogicalConditions({
                  targetObjectId,
                  choiceId: eachOption.value,
                });
              }}
            >
              <CheckboxContainer>
                <XtraCheckbox
                  disabled
                  backgroundColor={themeColor}
                >
                  {eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}
                </XtraCheckbox>
              </CheckboxContainer>
              <LogicalRelatedQuestion choiceId={eachOption.value} />
            </OptionContainer>
          )
        }
      </StyledXtraFlexbox>
    ))}
  </Container>
);

LogicalCheckBoxGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  themeColor: PropTypes.string.isRequired,
  targetObjectId: PropTypes.string.isRequired,
  currentEditLogicalChoiceId: PropTypes.string.isRequired,
  fetchLogicalConditions: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  currentEditLogicalChoiceId: state.currentEditLogicalOption.choiceId,
});

const mapDispatch = ({
  currentEditLogicalOption: { fetchLogicalConditions },
}) => ({
  fetchLogicalConditions,
});

export default connect(
  mapState,
  mapDispatch,
)(LogicalCheckBoxGroup);
