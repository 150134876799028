
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { XtraText, XtraFlexbox } from 'components';
import dropdown from 'registration_form_builder/Image/dropdownWhite.svg';
import LogicalQuestionEditor from './LogicalQuestionEditor';
import LogicalRelatedQuestion from './LogicalRelatedQuestion';

const Container = styled.div`
  padding: 0 32px;
`;

const SelectionContainer = styled(XtraFlexbox)`
  width: 100%;
  line-height: 40px;
  cursor: pointer;
`;

const OptionContainer = styled.div`
  width: 100%;
`;

const StyledXtraFlexbox = styled(XtraFlexbox)`
  min-height: 48px;
  position: relative;
`;

const StyledIcon = styled.img`
  margin-right: 8px;
`;

const LogicalDropDownGroup = ({
  choices, targetObjectId, currentEditLogicalChoiceId, fetchLogicalConditions,
}) => (
  <Container>
    {choices
      && choices.map((eachOption) => (
        <StyledXtraFlexbox
          alignItems="normal"
          key={eachOption.value}
        >
          {
            eachOption.value === currentEditLogicalChoiceId ? (
              <LogicalQuestionEditor
                value={eachOption.value}
                label={eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}
              />
            ) : (
              <OptionContainer
                onClick={() => {
                  fetchLogicalConditions({
                    targetObjectId,
                    choiceId: eachOption.value,
                  });
                }}
              >
                <SelectionContainer>
                  <StyledIcon src={dropdown} />
                  <XtraText>{eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}</XtraText>
                </SelectionContainer>
                <LogicalRelatedQuestion choiceId={eachOption.value} />
              </OptionContainer>
            )
          }
        </StyledXtraFlexbox>
      ))}
  </Container>
);

LogicalDropDownGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  targetObjectId: PropTypes.string.isRequired,
  currentEditLogicalChoiceId: PropTypes.string.isRequired,
  fetchLogicalConditions: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  currentEditLogicalChoiceId: state.currentEditLogicalOption.choiceId,
});

const mapDispatch = ({
  currentEditLogicalOption: { fetchLogicalConditions },
}) => ({
  fetchLogicalConditions,
});

export default connect(
  mapState,
  mapDispatch,
)(LogicalDropDownGroup);
