
import styled from 'styled-components';
import CheckBox from 'components/XtraCheckbox';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

const CheckboxContainer = styled.div`
  padding: 0 32px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  position: relative;
`;

const handleOnChnage = (
  handleChange,
  attendeeId,
  targetObjectId,
  questionAnswer,
  value,
) => {
  const newQuestionAnswer = _cloneDeep(questionAnswer);
  if (questionAnswer.includes(value)) {
    newQuestionAnswer.splice(questionAnswer.indexOf(value), 1);
  } else {
    newQuestionAnswer.push(value);
  }
  handleChange({
    attrName: 'questionAnswer',
    valueType: 'COLLECTION',
    attendeeId,
    targetObjectId,
    value: newQuestionAnswer,
  });
};

const CheckBoxGroup = ({
  choices,
  questionAnswer,
  handleChange,
  isDisabled,
  themeColor,
  attendeeId,
  targetObjectId,
}) => (
  <CheckboxContainer>
    {choices
    && choices.map((eachOption) => (
      <Item key={eachOption.value}>
        <CheckBox
          checked={questionAnswer.includes(eachOption.value)}
          onChange={() => {
            handleOnChnage(
              handleChange,
              attendeeId,
              targetObjectId,
              questionAnswer,
              eachOption.value,
            );
          }}
          disabled={isDisabled}
          backgroundColor={themeColor}
          initFontStyle
        >
          {eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}
        </CheckBox>
      </Item>
    ))}
  </CheckboxContainer>
);

CheckBoxGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  questionAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetObjectId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
};

CheckBoxGroup.defaultProps = {
  handleChange: () => {},
};

export default CheckBoxGroup;
