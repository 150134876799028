import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import ICalendarLink from 'react-icalendar-link';
import CalendarSrc from 'registration_form_builder/Image/calendar.svg';

const CalendarButtonComponentContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 24px;

  .react-add-to-calendar {
    z-index: 1;
    font-family: inherit
  }
  .react-add-to-calendar__button {
    border: 2px solid #DBDEE2;

  }
  .react-add-to-calendar__dropdown{
    width: 100%;
  }
`;

const CalendarIcon = styled.img`
  margin: 0 4px;
`;

interface CalendarButtonComponentProps {
  eventInfo: {
    startAt,
    endAt,
    name,
    place,
  }
}

const CalendarButtonComponent: FC<CalendarButtonComponentProps> = ({
  eventInfo: {
    startAt: startTime,
    endAt: endTime,
    name: title,
    place: eventLocation,
  },
}) => {
  const { t } = useTranslation();
  const location = useMemo(() => {
    if (!eventLocation) {
      return '';
    }

    return eventLocation.replace(new RegExp(',', 'g'), '\\,');
  }, [eventLocation]);
  const event = useMemo(() => ({
    title,
    startTime,
    endTime,
    description: '',
    location,
  }), [title, startTime, endTime, location]);

  return (
    <CalendarButtonComponentContainer onClick={(e) => e.stopPropagation()}>
      <ICalendarLink
        event={event}
      >
        <Button variant="outlined">
          <CalendarIcon src={CalendarSrc} />
          {t('regform_v3_builder.thank_you_page_view.add_to_my_calendar', 'Add to Calendar')}
        </Button>
      </ICalendarLink>
    </CalendarButtonComponentContainer>
  );
};

const mapState = (state) => ({
  eventInfo: state.eventInfo,
});

export default connect(mapState)(CalendarButtonComponent);
