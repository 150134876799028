import {
  useEffect,
  FC,
  useState,
} from 'react';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import FormValueType from 'schema/formValueType';
import { TextField } from '@material-ui/core';
import { HandleChangeProps } from 'schema/xtraTextInput';

const DropDownContainer = styled.div`
  width: 100%;
  padding: 8px 24px 16px;
`;

interface DropdownOption {
  label: string;
  value: string;
}

interface GenericDropDownProps {
  value: DropdownOption;
  initialValue?: string;
  placeholder: string;
  disabled: boolean;
  options: DropdownOption[];
  themeColor: string;
  handleChange: (props: HandleChangeProps) => any;
  targetObjectId: string;
  attendeeId: string;
}

const GenericDropdown: FC<GenericDropDownProps> = ({
  placeholder,
  disabled,
  initialValue,
  handleChange,
  value,
  targetObjectId,
  attendeeId,
  options,
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.label ?? '');
  const handleOnSelect = (e, selectedOption) => {
    const { value: selectedValue } = selectedOption;
    handleChange({
      attrName: 'questionAnswer',
      valueType: FormValueType.STRING,
      targetObjectId,
      attendeeId,
      value: selectedValue,
    });
  };

  useEffect(() => {
    if (value?.label) {
      setInputValue(value.label);
    }
  }, [value]);

  useEffect(() => {
    if (initialValue) {
      handleOnSelect({}, {
        value: initialValue,
      });
    }
  }, []);

  return (
    <DropDownContainer>
      <Autocomplete
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, selectedValue) => selectedValue === option.value}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        }}
        fullWidth
        disabled={disabled}
        disableClearable
        onChange={handleOnSelect}
        placeholder={placeholder}
        renderOption={(option) => <>{option.label}</>}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
              style: {
                padding: '6px ​8px !important',
              },
            }}
          />
        )}
      />
    </DropDownContainer>
  );
};

export default GenericDropdown;
