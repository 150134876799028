
import PropTypes from 'prop-types';

const FileIcon = ({ fillColor }) => (
  <svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fillColor} fillRule="evenodd" clipRule="evenodd" d="M33.0594 11.2061L22.9719 1.1186C22.6906 0.837353 22.3109 0.677979 21.9125 0.677979H2C1.17031 0.677979 0.5 1.34829 0.5 2.17798V41.178C0.5 42.0077 1.17031 42.678 2 42.678H32C32.8297 42.678 33.5 42.0077 33.5 41.178V12.2702C33.5 11.8717 33.3406 11.4874 33.0594 11.2061ZM30.0406 12.9593H21.2188V4.13739L30.0406 12.9593ZM3.875 39.303H30.125V16.1467H20C18.9125 16.1467 18.0312 15.2655 18.0312 14.178V4.05298H3.875V39.303ZM16.625 26.6467H8C7.79375 26.6467 7.625 26.8155 7.625 27.0217V29.2717C7.625 29.478 7.79375 29.6467 8 29.6467H16.625C16.8312 29.6467 17 29.478 17 29.2717V27.0217C17 26.8155 16.8312 26.6467 16.625 26.6467ZM7.625 22.8967V20.6467C7.625 20.4405 7.79375 20.2717 8 20.2717H26C26.2062 20.2717 26.375 20.4405 26.375 20.6467V22.8967C26.375 23.103 26.2062 23.2717 26 23.2717H8C7.79375 23.2717 7.625 23.103 7.625 22.8967Z" />
  </svg>

);

FileIcon.propTypes = {
  fillColor: PropTypes.string,
};

FileIcon.defaultProps = {
  fillColor: '#CF2F2F',
};

export default FileIcon;
