enum RegformFieldElement {
  AccessCode = 'RegformAccessCodeFieldElement',
  CheckboxMcQuestion = 'RegformCheckboxMcQuestionFieldElement',
  CheckboxQuestion = 'RegformCheckboxQuestionFieldElement',
  City = 'RegformCityFieldElement',
  Company = 'RegformCompanyFieldElement',
  Country = 'RegformCountryFieldElement',
  DropdownMcQuestion = 'RegformDropdownMcQuestionFieldElement',
  FileUploadQuestion = 'RegformFileUploadQuestionFieldElement',
  FirstName = 'RegformFirstNameFieldElement',
  Industry = 'RegformIndustryFieldElement',
  LastName = 'RegformLastNameFieldElement',
  Phone = 'RegformPhoneFieldElement',
  PrimaryEmail = 'RegformPrimaryEmailFieldElement',
  RadioMcQuestion = 'RegformRadioMcQuestionFieldElement',
  Salutation = 'RegformSalutationFieldElement',
  SecondaryEmail = 'RegformSecondaryEmailFieldElement',
  StringQuestion = 'RegformStringQuestionFieldElement',
  TextQuestion = 'RegformTextQuestionFieldElement',
  Title = 'RegformTitleFieldElement',
  WaiverQuestion = 'RegformWaiverQuestionFieldElement',
  Calendar = 'RegformAddToCalendarButtonElement',
}

export default RegformFieldElement;
