/* eslint-disable react/prop-types */
import { useMemo, FC } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import sortBy from 'lodash/sortBy';

const DropDownContainer = styled.div`
  width: 20%;
  min-width: 100px;
`;

const StyledTextField = styled(TextField)<{ themeColor: string; activeMode: boolean; }>`
  width: 100%;
  padding-right: 8px !important;
  border-radius: 4px;
  background-color: white;
  border:
    2px solid
    ${(props) => {
    if (props.activeMode) {
      return props.themeColor;
    }
    return '#dbdee2';
  }};
  transition: all 0.3s;
  font-size: 14px;

  &:focus {
    outline: none !important;
    border:
      2px solid
      ${({ themeColor }) => (themeColor)};
  }
`;

const MenuItemContainer = styled.div`
  width: 100%;
  white-space: normal;
`;

const CountryNameText = styled.span`
  margin-right: 6px;
`;

const DialCodeText = styled.span`
  color: #999;
`;

const useMenuItemStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface DialCodeDropDownProps {
  value: string;
  disabled: boolean;
  activeMode: boolean;
  themeColor: string;
  handleChange: (e: any) => any;
  locale: {
    code: string
    display: string
    emsMapping: string
  };
}

const DialCodeDropDown: FC<DialCodeDropDownProps> = ({
  disabled,
  handleChange,
  value,
  locale,
  activeMode,
  themeColor,
}) => {
  const { t } = useTranslation();

  const countries = useMemo(() => {
    const options = getCountries()
      .map((countryCode) => ({
        name: t(`country.${countryCode.toLowerCase()}`, { locale: ['ja', 'ko'].includes(locale.emsMapping) ? 'en' : locale.emsMapping, defaultValue: '' }),
        dialCode: getCountryCallingCode(countryCode),
        countryCode,
      }))
      .filter(({ name }) => name?.length);
    return sortBy(options, ['name']);
  }, [locale]);

  const menuItemClasses = useMenuItemStyles();

  return (
    <DropDownContainer>
      <StyledTextField
        id="phone-dial-code"
        value={value}
        onChange={handleChange}
        variant="outlined"
        disabled={disabled}
        activeMode={activeMode}
        themeColor={themeColor}
        select
        SelectProps={{
          renderValue: (v) => `+${v}`,
        }}
      >
        {
          countries.map((country) => (
            <MenuItem
              key={country.countryCode}
              className={menuItemClasses.root}
              value={country.dialCode as string}
            >
              <MenuItemContainer>
                <CountryNameText>
                  {country.name}
                </CountryNameText>
                <DialCodeText>
                  {` (+${country.dialCode})`}
                </DialCodeText>
              </MenuItemContainer>
            </MenuItem>
          ))
        }
      </StyledTextField>
    </DropDownContainer>
  );
};

export default DialCodeDropDown;
