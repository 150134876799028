
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
`;

const RadioButtonContainer = styled.div`
  padding: 0 32px;
`;

const RadioGroup = ({
  choices,
  questionAnswer,
  handleChange,
  isDisabled,
  themeColor,
  targetObjectId,
  attendeeId,
}) => (
  <RadioButtonContainer>
    {choices
    && choices.map((eachOption) => (
      <Item key={eachOption.value}>
        <RadioButton
          type="radio"
          backgroundColor={themeColor}
          value={eachOption.value}
          checked={questionAnswer.length > 0
            && questionAnswer[0] === eachOption.value}
          onChange={() => handleChange({
            attrName: 'questionAnswer',
            targetObjectId,
            attendeeId,
            valueType: 'COLLECTION',
            value: [eachOption.value],
          })}
          label={eachOption.label !== '' ? eachOption.label : eachOption.labelPlaceholder}
          disabled={isDisabled}
          attendeeId={attendeeId}
        />
      </Item>
    ))}
  </RadioButtonContainer>
);

RadioGroup.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  questionAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetObjectId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
};

RadioGroup.defaultProps = {
  handleChange: () => {},
};

export default RadioGroup;
