import { useEffect } from 'react';
import styled from 'styled-components';
import CheckBox from 'components/XtraCheckbox';
import RequiredDot from 'components/RequiredDot';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormValueType from 'schema/formValueType';

const Container = styled.div`
  padding: 0 32px;
`;

const CheckBoxContainer = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #484848;
  vertical-align: top;
`;

const SingleCheckBox = ({
  handleChange,
  themeColor,
  disabled,
  targetObjectId,
  attendeeId,
  initialValue,
  checkedByDefault,
  locale,
  value,
  initFontStyle,
  type,
  labelText,
  required,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (attendeeId) {
      handleChange({
        attrName: 'questionAnswer',
        valueType: FormValueType.BOOLEAN,
        targetObjectId,
        attendeeId,
        value: Boolean(checkedByDefault || initialValue),
      });
    }
  }, []);
  return (
    <Container>
      <CheckBoxContainer>
        <CheckBox
          backgroundColor={themeColor}
          onChange={({ target: { checked } }) => {
            handleChange({
              attrName: 'questionAnswer',
              valueType: FormValueType.BOOLEAN,
              targetObjectId,
              attendeeId,
              value: checked,
            });
          }}
          checked={value}
          disabled={disabled}
          defaultChecked={Boolean(checkedByDefault || initialValue)}
          initFontStyle={initFontStyle}
        >
          {
            type === 'singleCheckbox' ? (
              <>
              {required && <RequiredDot />}
              {labelText}
              </>
            ) : (
              t('registration_config.agree_waiver', { lng: locale.emsMapping })
            )
          }
        </CheckBox>
      </CheckBoxContainer>
    </Container>
  );
};

SingleCheckBox.propTypes = {
  checkedByDefault: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  themeColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  targetObjectId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
  initialValue: PropTypes.bool,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  initFontStyle: PropTypes.bool,
  updateComponentAttrMutation: PropTypes.func,
  value: PropTypes.bool.isRequired,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

SingleCheckBox.defaultProps = {
  handleChange: () => {},
  disabled: false,
  initialValue: false,
  initFontStyle: false,
  updateComponentAttrMutation: () => {},
  labelText: '',
  required: false,
};

export default SingleCheckBox;
