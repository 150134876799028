import { TextFieldProps } from '@material-ui/core';
import { FC } from 'react';
import FormValueType from 'schema/formValueType';
import { XtraTextInputProps } from 'schema/xtraTextInput';
import validator from 'validator';
import TextInput from './TextInput';

interface EmailInputProps extends XtraTextInputProps {
  updateComponentAttr: any;
}

const EmailInput: FC<EmailInputProps & TextFieldProps> = ({ handleChange, updateComponentAttr, ...props }) => (
  <TextInput
    attrName="questionAnswer"
    handleChange={({ value: targetValue, attendeeId, targetObjectId }) => {
      handleChange({
        attrName: 'questionAnswer',
        valueType: FormValueType.STRING,
        targetObjectId,
        attendeeId,
        value: targetValue,
      });
      if (updateComponentAttr) {
        let errorMessage = '';
        if (!validator.isEmail(targetValue)) {
          errorMessage = 'regsvp.error.invalid_email';
        }
        updateComponentAttr({
          attendeeId,
          targetObjectId,
          attrName: 'errorMessage',
          value: errorMessage,
        });
      }
    }}
    type="email"
    {...props}
  />
);

export default EmailInput;
