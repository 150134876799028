import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #BEBEBE;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  &:hover ~ ${RadioButtonLabel} {
    background: #BEBEBE;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 3px;
      background: #EEE;
    }
  }

  &:checked + ${RadioButtonLabel} {
    background-color: white;

    &::after {
      content: '';
      background-color: ${({ backgroundColor }) => backgroundColor};
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 3px;
    }
  }
`;

const RadioButtonText = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  transform: translateX(8px);
`;

const SublabelContainer = styled.div`
  font-size: 0.6rem;
`;

const CustomRadioButton = ({
  backgroundColor,
  value,
  checked,
  onChange,
  disabled,
  label,
  sublabel,
  attendeeId,
}) => (
  <>
    <RadioButton
      id={`${value + attendeeId}`}
      type="radio"
      backgroundColor={backgroundColor}
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <RadioButtonLabel />
    <RadioButtonText htmlFor={`${value + attendeeId}`}>
      <div>
        {label}
        {!!sublabel && '*'}
      </div>
      {sublabel && (
        <SublabelContainer>
          {`*${sublabel}`}
        </SublabelContainer>
      )}
    </RadioButtonText>
  </>
);

CustomRadioButton.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  attendeeId: PropTypes.string,
  sublabel: PropTypes.string,
};

CustomRadioButton.defaultProps = {
  onChange: null,
  attendeeId: '',
  sublabel: null,
};

export default CustomRadioButton;
