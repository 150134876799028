import styled from 'styled-components';
import FormValueType from 'schema/formValueType';
import { TextField, TextFieldProps } from '@material-ui/core';
import { FC } from 'react';
import { XtraTextInputProps } from 'schema/xtraTextInput';

const TextInputContainer = styled.div`
  width: 100%;
  padding: 8px 24px 16px;
`;

const StyledTextField = styled(TextField)<{ themeColor: string; activeMode: boolean; error: boolean | string }>`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  border:
    2px solid
    ${(props) => {
    if (props.error) {
      return 'red';
    }
    if (props.activeMode) {
      return props.themeColor;
    }
    return '#dbdee2';
  }};
  transition: all 0.3s;
  font-size: 14px;

  &:focus {
    outline: none !important;
    border:
      2px solid
      ${({ themeColor }) => (themeColor)};
  }
`;

const TextInput: FC<TextFieldProps & XtraTextInputProps> = ({
  themeColor,
  activeMode,
  forceComponentAttrMutation,
  targetObjectId,
  attendeeId,
  attrName,
  handleChange,
  style,
  error,
  ...props
}) => (
  <TextInputContainer style={style}>
    <StyledTextField
      {...props}
      onChange={({ target: { value: inputValue } }) => {
        handleChange({
          attrName,
          valueType: FormValueType.STRING,
          targetObjectId,
          attendeeId,
          value: inputValue,
        });
      }}
      themeColor={themeColor}
      activeMode={activeMode}
      variant="outlined"
      error={error || false}
      onBlur={forceComponentAttrMutation as any}
    />
  </TextInputContainer>
);

export default TextInput;
