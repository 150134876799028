import styled from 'styled-components';

const FormComponentContainer = styled.div`
  width: 100%;
  height: auto;
  margin: auto;
  padding: 16px 24px 0 24px;
`;

export default FormComponentContainer;
