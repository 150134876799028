import styled from 'styled-components';

const QuestionHint = styled.label`
  display: block;
  font-size: 13px;
  color: #484848;
  letter-spacing: 0.3px;
  padding-top: 8px;
  font-weight: 400;
`;

export default QuestionHint;
