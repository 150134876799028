/* eslint-disable camelcase */
interface TicketByLocale {
  ticket: {
    price: {
      amount: string;
    }
  }
}

interface TicketFragment {
  id: string;
  en: TicketByLocale;
  zh_hk: TicketByLocale;
  zh_cn: TicketByLocale;
  ja: TicketByLocale;
  ko: TicketByLocale;
  ru: TicketByLocale;
  selectedQuatity: number;
}

const priceReducer = (accumulator, { selectedQuatity, ticket: { price: { amount } } }) => (
  accumulator + selectedQuatity * parseFloat(amount)
);

const isAnyTicketPaid = (
  ticket: TicketFragment[],
  actualPrice: string,
  locale: { code: string },
  discountCodeEnabled: boolean,
): boolean => {
  if (discountCodeEnabled) {
    const actualPriceAmount = parseFloat(actualPrice);
    return actualPriceAmount > 0;
  }
  const totalPriceAmount = ticket.map((eachTicket) => ({
    id: eachTicket.id,
    selectedQuatity: eachTicket.selectedQuatity,
    ticket: eachTicket[locale.code].ticket,
  })).reduce(priceReducer, 0);
  return totalPriceAmount > 0;
};

export default isAnyTicketPaid;
