import { useEffect, useRef } from 'react';
import FormValueType from 'schema/formValueType';
import GenericDropdown from './GenericDropdown';

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const SalutationDropDown = ({
  salutations,
  questionAnswer,
  placeholder,
  disabled,
  attendeeId,
  targetObjectId,
  handleChange,
  themeColor,
  locale,
  footerColorMode,
}) => {
  let lastLocale = usePrevious(locale);
  if (!lastLocale) {
    lastLocale = locale;
  }
  useEffect(() => {
    if (questionAnswer.length > 0 && lastLocale) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const index = salutations[(lastLocale || {})?.emsMapping]?.findIndex(
        (eachSalutation) => (questionAnswer === eachSalutation),
      );
      if (index !== undefined && index > -1) {
        handleChange({
          targetObjectId,
          attendeeId,
          attrName: 'questionAnswer',
          valueType: FormValueType.TEXTCHOICE,
          value: [salutations[locale.emsMapping][index]],
        });
      }
    }
  }, [locale]);
  return (
    <GenericDropdown
      themeColor={themeColor}
      placeholder={placeholder}
      value={questionAnswer.length
        ? { value: questionAnswer[0], label: questionAnswer[0] }
        : undefined}
      disabled={disabled}
      options={salutations[locale.emsMapping].map((eachSalutation) => ({
        value: eachSalutation,
        label: eachSalutation,
      }))}
      targetObjectId={targetObjectId}
      attendeeId={attendeeId}
      handleChange={(newSelectedOption) => {
        handleChange({
          targetObjectId,
          attendeeId,
          attrName: 'questionAnswer',
          valueType: FormValueType.TEXTCHOICE,
          value: [newSelectedOption.value],
        });
      }}
    />
  );
};

export default SalutationDropDown;
