import styled from 'styled-components';
import { XtraButton } from 'components';
import PropTypes from 'prop-types';

const SubmitContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 16px 36px;
`;

const SubmitButton = styled(XtraButton)`
  background-color: ${({ themeColor }) => (themeColor)};
  border:
    2px solid
    ${({ themeColor }) => (themeColor)} !important;
  color: white;
  transition: all 0.3s;
  padding: auto 16px;
  font-family: unset;
  line-height: 14px;

  &:hover, &:focus, &:disabled {
    background-color: white !important;
    color: ${({ themeColor }) => (themeColor)};
    border:
      2px solid
      ${({ themeColor }) => (themeColor)} !important;
  }
`;

const SubmitButtonContainer = ({
  themeColor,
  submitText,
  isSubmitingText,
  isSubmiting,
  onClickFunc,
  isDisabled,
}) => (
  <SubmitContainer>
    <SubmitButton
      themeColor={themeColor}
      onClick={onClickFunc}
      size="large"
      isSubmiting={isSubmiting}
      disabled={isDisabled}
    >
      {isSubmiting ? isSubmitingText : submitText}
    </SubmitButton>
  </SubmitContainer>
);

SubmitButtonContainer.propTypes = {
  themeColor: PropTypes.string.isRequired,
  isSubmiting: PropTypes.bool.isRequired,
  submitText: PropTypes.string.isRequired,
  isSubmitingText: PropTypes.string.isRequired,
  onClickFunc: PropTypes.func,
  isDisabled: PropTypes.bool.isRequired,
};

SubmitButtonContainer.defaultProps = {
  onClickFunc: () => {},
};

export default SubmitButtonContainer;
