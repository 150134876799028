import {
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import useCountryCodeOptions from 'hooks/useCountryCodeOptions';
import { useTranslation } from 'react-i18next';
import GenericDropdown from './GenericDropdown';

const CountryDropDown = ({
  placeholder,
  disabled,
  handleChange,
  value,
  userLocation,
  themeColor,
  locale,
  targetObjectId,
  attendeeId,
}) => {
  const { t } = useTranslation();
  const options = useCountryCodeOptions();
  const chinaFriendlyModeEnabled = useSelector((s) => s.featureGuard.chinaFriendlyModeEnabled);
  const currentValue = useMemo(() => {
    if (chinaFriendlyModeEnabled && ['hk', 'mo', 'tw'].includes(value)) {
      return {
        value,
        label: t(`country.${value}_cn`, { locale: ['ja', 'ko'].includes(locale.emsMapping) ? 'en' : locale.emsMapping }),
      };
    }
    return {
      value,
      label: t(`country.${value}`, { locale: ['ja', 'ko'].includes(locale.emsMapping) ? 'en' : locale.emsMapping }),
    };
  }, [value, locale, t, chinaFriendlyModeEnabled]);

  return (
    <GenericDropdown
      initialValue={value || userLocation || 'hk'}
      targetObjectId={targetObjectId}
      attendeeId={attendeeId}
      options={options}
      value={currentValue}
      placeholder={placeholder}
      disabled={disabled}
      handleChange={handleChange}
      themeColor={themeColor}
    />
  );
};

export default CountryDropDown;
