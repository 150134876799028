import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import XtraHintText from 'components/XtraHintText';
import { useTranslation } from 'react-i18next';
import RequiredDot from 'components/RequiredDot';
import RegformFieldElement from 'schema/fieldElement';
import RegformDisplayElement from 'schema/displayElement';
import isEmailField from 'helpers/isEmailField';
import isTextField from 'helpers/isTextField';
import shouldDisplayInputTitle from 'helpers/shouldDisplayInputTitle';
import isSingleBooleanField from 'helpers/isSingleBooleanField';
import TextInput from './TextInput';
import SalutationDropDown from './SalutationDropDown';
import EmailInput from './EmailInput';
import TextAreaInput from './TextAreaInput';
import PhoneInput from './PhoneInput';
import IndustryDropDown from './IndustryDropDown';
import StaticText from './StaticText';
import StaticImage from './StaticImage';
import CountryDropDown from './CountryDropDown';
import FileUploadDropZone from './FileUploadDropZone';
import RadioGroup from './RadioGroup';
import CheckBoxGroup from './CheckBoxGroup';
import DropDown from './DropDown';
import SingleCheckBox from './SingleCheckBox';
import ErrorMessage from './ErrorMessage';
import LogicalDropDownGroup from './LogicalDropDownGroup';
import LogicalRadioGroup from './LogicalRadioGroup';
import LogicalCheckBoxGroup from './LogicalCheckBoxGroup';
import FormComponentContainer from './FormComponentContainer';
import QuestionTitle from './QuestionTitle';
import QuestionHint from './QuestionHint';
import CalendarButtonComponent from './CalendarButtonComponent';

const FormField = styled.div`
  width: 100%;
  height: auto;
`;

const WaiverTitle = styled.label`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #484848;
  margin-bottom: 16px;
`;

const WaiverContentContainer = styled.div`
  width: 100%;
  margin-bottom: 6px;
`;

const WaiverContent = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 2px solid #DBDEE2;
  padding: 8px 16px;
  max-height: 300px;
  overflow: auto;
  word-break: break-word;
  margin-bottom: 8px;
  list-style-position: inside;

  p {
    margin: 0;

    :empty::before {
      content: ' ';
      white-space: pre;
    }
  }
`;

const ErrorMessageContainer = styled.div`
  padding: 0 24px;
`;

const StyleXtraHintText = styled(XtraHintText)`
  padding-left: 24px;
  color: #5C8DF3;
  letter-spacing: 0.3px;
`;
const SingleCheckBoxContainer = styled.div`
  width: 100%;
  padding: 16px 0;
`;

const isEmptyHtml = (html) => (html === null || html === '' || html === '<p></p>');

const getText = (...texts) => (
  texts.find((text) => (text !== '' && text !== '<p></p>' && text !== null))
);

const getValueNullFallback = (...values) => (
  values.find((value) => (value !== null))
);

const renderTitleAndHintByValueType = (eachComponent, attendeeId) => {
  const generatedTitle = getText(eachComponent.labelText, eachComponent.labelTextPlaceholder);
  const generatedHint = getValueNullFallback(
    eachComponent.hintHtml,
    eachComponent.hintText,
    eachComponent.hintTextPlaceholder,
  );
  if (shouldDisplayInputTitle(eachComponent.typeName)) {
    return (
      <FormComponentContainer>
        <QuestionTitle htmlFor={eachComponent.id + attendeeId}>
          {eachComponent.required && <RequiredDot />}
          {generatedTitle}
        </QuestionTitle>
        {!isEmptyHtml(generatedHint) && (
          <QuestionHint htmlFor={eachComponent.id + attendeeId}>
            {ReactHtmlParser(generatedHint)}
          </QuestionHint>
        )}
      </FormComponentContainer>
    );
  }
  if (eachComponent.typeName === RegformFieldElement.WaiverQuestion) {
    return (
      <FormComponentContainer>
        <WaiverTitle htmlFor={eachComponent.id + attendeeId}>
          {eachComponent.required && <RequiredDot />}
          {generatedTitle}
        </WaiverTitle>
        <WaiverContentContainer>
          <WaiverContent>
            {ReactHtmlParser(eachComponent.html)}
          </WaiverContent>
        </WaiverContentContainer>
      </FormComponentContainer>
    );
  }
  return null;
};

const renderInputByValueType = (
  eachComponent,
  questionAnswer,
  errorMessage,
  isDisabled,
  updateComponentAttr,
  handleChange,
  removeFormComponent,
  active,
  themeColor,
  forceComponentAttrMutation,
  industriesOption,
  userLocation,
  salutations,
  locale,
  attendeeId,
  isLogicSetting,
  updateComponentAttrMutation,
  footerColorMode,
  t,
) => {
  if (isTextField(eachComponent.typeName)) {
    return (
      <TextInput
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        type="text"
        placeholder={eachComponent.placeholderText !== '' ? eachComponent.placeholderText : eachComponent.placeholderTextPlaceholder}
        disabled={isDisabled}
        activeMode={active}
        attrName="questionAnswer"
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.Salutation) {
    return (
      <SalutationDropDown
        salutations={salutations}
        questionAnswer={questionAnswer}
        placeholder={eachComponent.placeholderText}
        disabled={isDisabled}
        activeMode={active}
        attrName="questionAnswer"
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        updateComponentAttr={updateComponentAttr}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
        locale={locale}
        footerColorMode={footerColorMode}
      />
    );
  }
  if (isEmailField(eachComponent.typeName)) {
    return (
      <EmailInput
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        placeholder={eachComponent.placeholderText || eachComponent.placeholderTextPlaceholder}
        disabled={isDisabled}
        activeMode={active}
        attrName="questionAnswer"
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        updateComponentAttr={updateComponentAttr}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.TextQuestion) {
    return (
      <TextAreaInput
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        placeholder={eachComponent.placeholderText || eachComponent.placeholderTextPlaceholder}
        disabled={isDisabled}
        activeMode={active}
        attrName="questionAnswer"
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.Phone) {
    return (
      <PhoneInput
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        placeholder={eachComponent.placeholderText || eachComponent.placeholderTextPlaceholder}
        disabled={isDisabled}
        activeMode={active}
        attendeeId={attendeeId}
        required={eachComponent.required}
        targetObjectId={eachComponent.id}
        handleChange={handleChange}
        updateComponentAttr={updateComponentAttr}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
        locale={locale}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.Industry) {
    return (
      <IndustryDropDown
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        placeholder={
          questionAnswer
          || eachComponent.placeholderText
          || eachComponent.placeholderTextPlaceholder
        }
        questionAnswer={questionAnswer}
        disabled={isDisabled}
        activeMode={active}
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
        industriesOption={industriesOption}
        locale={locale}
        footerColorMode={footerColorMode}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.Country) {
    return (
      <CountryDropDown
        id={eachComponent.id + attendeeId}
        value={questionAnswer}
        placeholder={
          questionAnswer
          || eachComponent.placeholderText
          || eachComponent.placeholderTextPlaceholder
        }
        questionAnswer={questionAnswer}
        disabled={isDisabled}
        activeMode={active}
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        handleChange={handleChange}
        themeColor={themeColor}
        forceComponentAttrMutation={forceComponentAttrMutation}
        error={errorMessage !== null && errorMessage !== ''}
        userLocation={userLocation}
        locale={locale}
        footerColorMode={footerColorMode}
      />
    );
  }
  if (eachComponent.typeName === RegformDisplayElement.Text) {
    let text = '';
    if (eachComponent.html) {
      text = eachComponent.html;
    } else if (eachComponent.htmlPlaceholder) {
      text = eachComponent.htmlPlaceholder;
    } else {
      text = t('regsvp.static_text_default', {
        locale: locale.emsMapping,
      });
    }
    return (
      <StaticText
        text={text}
      />
    );
  }
  if (eachComponent.typeName === RegformDisplayElement.Image) {
    return <StaticImage imageSrc={eachComponent.url} />;
  }
  if (eachComponent.typeName === RegformFieldElement.RadioMcQuestion) {
    return (
      <>
        {
          isLogicSetting && isDisabled ? (
            <LogicalRadioGroup
              choices={eachComponent.availableChoices.choices}
              targetObjectId={eachComponent.id}
              themeColor={themeColor}
            />
          ) : (
            <RadioGroup
              choices={eachComponent.availableChoices.choices}
              attendeeId={attendeeId}
              targetObjectId={eachComponent.id}
              questionAnswer={questionAnswer}
              updateComponentAttr={updateComponentAttr}
              isDisabled={isDisabled}
              themeColor={themeColor}
              handleChange={handleChange}
              error={errorMessage !== null && errorMessage !== ''}
            />
          )
        }
      </>
    );
  }
  if (eachComponent.typeName === RegformFieldElement.CheckboxMcQuestion) {
    return (
      <>
        {
          isLogicSetting && isDisabled ? (
            <LogicalCheckBoxGroup
              choices={eachComponent.availableChoices.choices}
              targetObjectId={eachComponent.id}
              themeColor={themeColor}
            />
          ) : (
            <CheckBoxGroup
              choices={eachComponent.availableChoices.choices}
              attendeeId={attendeeId}
              targetObjectId={eachComponent.id}
              questionAnswer={questionAnswer}
              isDisabled={isDisabled}
              themeColor={themeColor}
              handleChange={handleChange}
              error={errorMessage !== null && errorMessage !== ''}
            />
          )
        }
      </>
    );
  }
  if (eachComponent.typeName === RegformFieldElement.DropdownMcQuestion) {
    return (
      <>
        {
          isLogicSetting && isDisabled ? (
            <LogicalDropDownGroup
              choices={eachComponent.availableChoices.choices}
              targetObjectId={eachComponent.id}
            />
          ) : (
            <DropDown
              choices={eachComponent.availableChoices.choices}
              attendeeId={attendeeId}
              targetObjectId={eachComponent.id}
              questionAnswer={questionAnswer}
              handleChange={handleChange}
              disabled={isDisabled}
              activeMode={active}
              themeColor={themeColor}
              error={errorMessage !== null && errorMessage !== ''}
              footerColorMode={footerColorMode}
            />
          )
        }
      </>
    );
  }
  if (eachComponent.typeName === RegformFieldElement.WaiverQuestion) {
    return (
      <SingleCheckBox
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        checkedByDefault={eachComponent.checkedByDefault}
        updateComponentAttr={updateComponentAttr}
        required={eachComponent.required}
        initialValue={questionAnswer}
        labelText={getText(eachComponent.labelText, eachComponent.labelTextPlaceholder)}
        handleChange={handleChange}
        themeColor={themeColor}
        disabled={isDisabled}
        locale={locale}
        value={questionAnswer}
        initFontStyle
        updateComponentAttrMutation={updateComponentAttrMutation}
        type="waiver"
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.CheckboxQuestion) {
    return (
      <SingleCheckBoxContainer>
        <SingleCheckBox
          attendeeId={attendeeId}
          targetObjectId={eachComponent.id}
          checkedByDefault={eachComponent.checkedByDefault}
          updateComponentAttr={updateComponentAttr}
          required={eachComponent.required}
          initialValue={questionAnswer}
          labelText={getText(eachComponent.labelText, eachComponent.labelTextPlaceholder)}
          handleChange={handleChange}
          themeColor={themeColor}
          disabled={isDisabled}
          locale={locale}
          value={questionAnswer}
          initFontStyle
          updateComponentAttrMutation={updateComponentAttrMutation}
          type="singleCheckbox"
        />
      </SingleCheckBoxContainer>
    );
  }
  if (eachComponent.typeName === RegformFieldElement.FileUploadQuestion) {
    return (
      <FileUploadDropZone
        attendeeId={attendeeId}
        targetObjectId={eachComponent.id}
        questionAnswer={questionAnswer}
        handleChange={handleChange}
        updateComponentAttr={updateComponentAttr}
        disabled={isDisabled}
        themeColor={themeColor}
        locale={locale}
      />
    );
  }
  if (eachComponent.typeName === RegformFieldElement.Calendar) {
    return (
      <CalendarButtonComponent />
    );
  }
  return null;
};

const ShouldRenderComponent = (eachComponentId, eachAttendee) => {
  const { mainForm: formQuestions } = eachAttendee;
  const targetComponent = formQuestions.find(({ id }) => id === eachComponentId);
  return targetComponent.shouldRender;
};

const FormComponent = ({
  locale,
  isDisabled,
  eachComponent,
  questionAnswer,
  errorMessage,
  updateComponentAttr,
  handleChange,
  removeFormComponent,
  active,
  themeColor,
  forceComponentAttrMutation,
  industriesOption,
  userLocation,
  salutations,
  dragHandleProps,
  attendeeId,
  isLogicSetting,
  eachAttendee,
  formKind,
  updateComponentAttrMutation,
  footerColorMode,
}) => {
  const { t } = useTranslation();
  let rsvpAnswer;
  if (formKind === 'rsvp' && isDisabled) {
    switch (eachComponent.typeName) {
      case RegformFieldElement.FirstName:
        rsvpAnswer = '{{attendee_first_name}}';
        break;
      case RegformFieldElement.LastName:
        rsvpAnswer = '{{attendee_last_name}}';
        break;
      case RegformFieldElement.Salutation:
        rsvpAnswer = '{{salutation}}';
        break;
      case RegformFieldElement.PrimaryEmail:
        rsvpAnswer = '{{primary_email}}';
        break;
      case RegformFieldElement.SecondaryEmail:
        rsvpAnswer = '{{secondary_email}}';
        break;
      case RegformFieldElement.Phone:
        rsvpAnswer = '{{phone}}';
        break;
      case RegformFieldElement.Country:
        rsvpAnswer = '{{country}}';
        break;
      case RegformFieldElement.City:
        rsvpAnswer = '{{city}}';
        break;
      case RegformFieldElement.Company:
        rsvpAnswer = '{{company}}';
        break;
      case RegformFieldElement.Title:
        rsvpAnswer = '{{job_title}}';
        break;
      case RegformFieldElement.Industry:
        rsvpAnswer = '{{industry}}';
        break;
      default: break;
    }
  }
  const rsvpHintShow = [
    RegformFieldElement.PrimaryEmail,
    RegformFieldElement.FirstName,
    RegformFieldElement.LastName,
  ].includes(eachComponent.typeName);
  if (!attendeeId || ShouldRenderComponent(eachComponent.id, eachAttendee)) {
    return (
      <FormField id={`Container${eachComponent.id + attendeeId}`} {...dragHandleProps}>
        {renderTitleAndHintByValueType(eachComponent, attendeeId)}
        {renderInputByValueType(
          eachComponent,
          rsvpAnswer || questionAnswer || '',
          errorMessage,
          isDisabled,
          updateComponentAttr,
          handleChange,
          removeFormComponent,
          active,
          themeColor,
          forceComponentAttrMutation,
          industriesOption,
          userLocation,
          salutations,
          locale,
          attendeeId,
          isLogicSetting,
          updateComponentAttrMutation,
          footerColorMode,
          t,
        )}
        {
          !isDisabled && (
            <ErrorMessageContainer>
              <ErrorMessage errorMessage={errorMessage} locale={locale} />
            </ErrorMessageContainer>
          )
        }
        {
          isDisabled && formKind === 'rsvp' && rsvpHintShow && (
            <StyleXtraHintText>
              {t('rsvp_invitation_batch.registration_form.field_disable', { locale: locale.emsMapping })}
            </StyleXtraHintText>
          )
        }
      </FormField>
    );
  }
  return null;
};

FormComponent.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  eachComponent: PropTypes.shape().isRequired,
  updateComponentAttr: PropTypes.func,
  handleChange: PropTypes.func,
  removeFormComponent: PropTypes.func,
  active: PropTypes.bool,
  themeColor: PropTypes.string.isRequired,
  forceComponentAttrMutation: PropTypes.func,
  industriesOption: PropTypes.arrayOf(PropTypes.string),
  userLocation: PropTypes.string,
  salutations: PropTypes.shape(),
  questionAnswer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape(),
  ]).isRequired,
  errorMessage: PropTypes.string,
  dragHandleProps: PropTypes.shape(),
  attendeeId: PropTypes.string,
  isLogicSetting: PropTypes.bool,
  eachAttendee: PropTypes.shape(),
  updateComponentAttrMutation: PropTypes.func,
  formKind: PropTypes.string,
  footerColorMode: PropTypes.string.isRequired,
};

FormComponent.defaultProps = {
  updateComponentAttr: null,
  handleChange: () => {},
  removeFormComponent: null,
  forceComponentAttrMutation: null,
  active: false,
  industriesOption: null,
  userLocation: 'hk',
  salutations: null,
  errorMessage: '',
  dragHandleProps: {},
  attendeeId: '',
  isLogicSetting: false,
  eachAttendee: {},
  updateComponentAttrMutation: () => {},
  formKind: '',
};

export default FormComponent;
