import styled from 'styled-components';

const QuestionTitle = styled.label`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: #484848;
`;

export default QuestionTitle;
