import RegformFieldElement from 'schema/fieldElement';

const isTextField = (typeName: RegformFieldElement): boolean => [
  RegformFieldElement.FirstName,
  RegformFieldElement.LastName,
  RegformFieldElement.City,
  RegformFieldElement.Company,
  RegformFieldElement.Title,
  RegformFieldElement.StringQuestion,
].includes(typeName);

export default isTextField;
