import { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const LanguageList = styled.div`
  width: 100%;
  padding: 4px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
`;

const LanguageContainer = styled.div`
  width: 100px;
  cursor: pointer;
  color: ${({ selected, themeColor }) => (selected ? themeColor : 'black')};

  &:hover {
    color: ${({ themeColor }) => (themeColor)};
  }
`;

const Divider = styled.div`
  border: 0.7px solid black;
`;

const LocaleSelector = ({
  locale, languageList, themeColor, setLocale,
}) => (
  languageList.length > 1 ? (
    <LanguageList>
      {languageList.map(({ code, display, emsMapping }, index) => (
        <Fragment key={code}>
          {index !== 0 && <Divider />}
          <LanguageContainer
            themeColor={themeColor}
            selected={code === locale.code}
            onClick={() => {
              setLocale({ code, display, emsMapping });
            }}
          >
            {display}
          </LanguageContainer>
        </Fragment>
      ))}
    </LanguageList>
  ) : null
);

LocaleSelector.propTypes = {
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  languageList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  themeColor: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
};

const mapState = (state) => ({
  locale: state.locale,
  languageList: state.languageList,
  themeColor: state.themeColor,
});

export default connect(
  mapState,
  null,
)(LocaleSelector);
