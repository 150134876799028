import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import {
  XtraFlexbox, XtraText, XtraCheckbox,
} from 'components';
import SavingIconSrc from 'registration_form_builder/Image/saving.svg';
import { useTranslation } from 'react-i18next';

const LogicalOptionContainer = styled(XtraFlexbox)`
  width: 100%;
  height: 100px;
  background: #F4F8FF;
  border-radius: 2px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 8px 0;
`;

const StyledXtraText = styled(XtraText)`
  line-height: 50px;
  font-size: 14px;
  margin-right: 16px;
  height: 50px;
  min-width: fit-content;
  color: ${({ color }) => color};
`;

const SelectContainer = styled(XtraFlexbox)`
  width: 80%;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledXtraCheckbox = styled(XtraCheckbox)`
  line-height: 40px;
  margin: 0;
`;

const StyledOption = styled(components.Option)`
  cursor: pointer !important;
  background-color: transparent !important;

  &:hover {
    background-color: #F0F1F2 !important;
  }

  &:active {
    background-color: #F4F8FF !important;
  }
`;

const Rotating = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
`;

const SavingIcon = styled.img`
  position: relative;
  left: -64px;
  animation: 1.5s ${Rotating} infinite ease-in-out;
  opacity: ${({ isSaving }) => (isSaving > 0 ? 100 : 0)};
`;

const CustomOptionComponent = (props) => {
  const { data: { label, value, isSelected } } = props;

  return (
    <StyledOption {...props} key={value}>
      <StyledXtraCheckbox
        checked={isSelected}
        backgroundColor="#5C8DF3 !important"
        disabled
      >
        {label}
      </StyledXtraCheckbox>
    </StyledOption>
  );
};

CustomOptionComponent.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    label: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
  }).isRequired,
};

const LogicalQuestionEditor = ({
  value: choiceId,
  label,
  currentEditLogicalOption,
  createLogicCondition,
  removeLogicCondition,
  locale,
  isSaving,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(currentEditLogicalOption.availableOptions);
  }, [currentEditLogicalOption]);

  const updateOptions = (targetObjectId) => {
    const newOptions = options.map((each) => {
      if (each.value === targetObjectId) {
        let option = each;
        option = {
          ...option,
          isSelected: !option.isSelected,
          isDisabled: true,
        };
        return option;
      }
      return each;
    });
    setOptions(newOptions);
  };

  const handleOptionSelect = ({ value: targetObjectId, isSelected, conditionId }) => {
    if (isSaving > 0) {
      return;
    }
    updateOptions(targetObjectId);
    if (isSelected && conditionId) {
      removeLogicCondition({ targetObjectId, optionId: conditionId });
    } else if (!isSelected) {
      createLogicCondition({ targetObjectId, choiceId });
    }
  };

  const values = options.filter(({ isSelected }) => isSelected);

  return (
    <LogicalOptionContainer direction="column" alignItems="normal">
      <XtraFlexbox>
        <StyledXtraText color="#A2AAB3">
          { t('regform_v3_builder.logical_question.setting.if_select', { locale: locale.emsMapping }) }
        </StyledXtraText>
        <StyledXtraText>
          {label}
        </StyledXtraText>
      </XtraFlexbox>
      <XtraFlexbox>
        <StyledXtraText>
          { t('regform_v3_builder.logical_question.setting.show_question', { locale: locale.emsMapping }) }
        </StyledXtraText>
        <SelectContainer>
          <StyledSelect
            options={options}
            value={values}
            onChange={handleOptionSelect}
            components={{ Option: CustomOptionComponent }}
            closeMenuOnSelect={false}
          />
          <SavingIcon src={SavingIconSrc} isSaving={isSaving} />
        </SelectContainer>
      </XtraFlexbox>
    </LogicalOptionContainer>
  );
};

LogicalQuestionEditor.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currentEditLogicalOption: PropTypes.shape({
    choiceId: PropTypes.string,
    availableOptions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  createLogicCondition: PropTypes.func.isRequired,
  removeLogicCondition: PropTypes.func.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  isSaving: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  currentEditLogicalOption: state.currentEditLogicalOption,
  locale: state.locale,
  isSaving: state.currentInQueue,
});

const mapDispatch = ({
  currentInQueue: { createLogicCondition, removeLogicCondition },
}) => ({
  createLogicCondition,
  removeLogicCondition,
});

export default connect(
  mapState,
  mapDispatch,
)(LogicalQuestionEditor);
