import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';
import FormValueType from 'schema/formValueType';

const mutationNameByFormValueType = {
  [FormValueType.STRING]: 'orderFieldSetTextValues',
  [FormValueType.COLLECTION]: 'orderFieldSetChoiceValues',
  [FormValueType.TEXTCHOICE]: 'orderFieldSetTextChoicesValues',
  [FormValueType.BOOLEAN]: 'orderFieldSetBooleanValue',
  [FormValueType.FILE]: 'orderFieldSetFileValue',
};

const mutationInputTypeByFormValueType = {
  [FormValueType.STRING]: 'OrderFieldSetTextValuesInput!',
  [FormValueType.COLLECTION]: 'OrderFieldSetChoiceValuesInput!',
  [FormValueType.TEXTCHOICE]: 'OrderFieldSetTextChoicesValuesInput!',
  [FormValueType.BOOLEAN]: 'OrderFieldSetBooleanValueInput!',
  [FormValueType.FILE]: 'OrderFieldSetFileValueInput!',
};

interface BatchAttendeeUpdate {
  attendeeId: string;
  fieldElementId: string;
  valueType: FormValueType;
  value: unknown;
}

const batchMutationWrapper = ({
  index,
  valueType,
}: {
  index: number;
  valueType: FormValueType;
}) => (`
setValue${index}: ${mutationNameByFormValueType[valueType]} (
  input: $input${index}
) {
  success
  errors {
    type
  }
  ${valueType === FormValueType.FILE ? `
  affectedElements {
    ...on OrderAttendeeFormFieldElement {
      value {
        ... on OrderAttendeeFileFieldValue {
          files {
            contentType
            filename
            fingerprint
            size
            url
          }
        }
      }
    }
  }
  ` : ''}
}
`);

interface CreateBatchAttendeeMutationProps {
  updates: BatchAttendeeUpdate[];
}

const createBatchAttendeeMutation = ({
  updates,
}: CreateBatchAttendeeMutationProps): DocumentNode => {
  const queryVariables = updates
    .map(({ valueType }, index) => `$input${index}: ${mutationInputTypeByFormValueType[valueType]}`)
    .join(', ');
  const mutationContent = updates.map(({ valueType }, index) => batchMutationWrapper({
    valueType,
    index,
  })).join('\n');
  try {
    const mutation = gql`
      mutation batchUpdateAttendeeMutation(${queryVariables}) {
        ${mutationContent}
      }
    `;
    return mutation;
  } catch (e) {
    console.error('Unable to perform batch mutation', JSON.stringify(updates), mutationContent);
    throw e;
  }
};

export default createBatchAttendeeMutation;
