import {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import parsePhoneNumber, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import FormValueType from 'schema/formValueType';
import DialCodeDropDown from './DialCodeDropDown';

const TextInputContainer = styled.div`
  width: 100%;
  padding: 8px 24px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: white;
  border:
    2px solid
    ${(props) => {
    if (props.error) {
      return 'red';
    }
    if (props.activeMode) {
      return props.themeColor;
    }
    return '#dbdee2';
  }};
  transition: all 0.3s;
  font-size: 14px;

  &:focus {
    outline: none !important;
    border:
      2px solid
      ${({ themeColor }) => (themeColor)};
  }
`;

const PhoneInput = ({
  id,
  placeholder,
  value,
  disabled,
  error,
  handleChange,
  updateComponentAttr,
  themeColor,
  style,
  required,
  activeMode,
  forceComponentAttrMutation,
  targetObjectId,
  attendeeId,
  userLocation,
  locale,
  ...props
}) => {
  const initialDialCode = useMemo(() => {
    try {
      return getCountryCallingCode(userLocation?.toUpperCase());
    } catch {
      return getCountryCallingCode('HK');
    }
  }, [userLocation]);
  const parsedValue = parsePhoneNumber(value);
  const [dialCode, setDialCode] = useState(parsedValue?.countryCallingCode ?? initialDialCode);
  const [phoneNum, setPhoneNum] = useState(parsedValue?.nationalNumber ?? '');

  const handleFullNumberChange = useCallback((code, phoneNumber) => {
    const fullNumber = code && phoneNumber ? `+${code}${phoneNumber}` : '';
    const isValid = isValidPhoneNumber(fullNumber);
    if (!disabled) {
      handleChange({
        targetObjectId,
        attendeeId,
        attrName: 'questionAnswer',
        valueType: FormValueType.STRING,
        value: fullNumber,
      });
      let errorMessage = '';
      if (!isValid && phoneNumber?.length) {
        errorMessage = 'regsvp.error.invalid_phone';
      }
      updateComponentAttr({
        targetObjectId,
        attendeeId,
        attrName: 'errorMessage',
        value: errorMessage,
      });
    }
  }, [attendeeId, disabled, handleChange, targetObjectId, updateComponentAttr]);

  const onDialCodeChange = useCallback((e) => {
    const code = e.target.value;
    const phoneNumber = '';

    setDialCode(code);
    setPhoneNum(phoneNumber);
    handleFullNumberChange(code, phoneNumber);
  }, [handleFullNumberChange]);

  const onPhoneNumChange = useCallback((e) => {
    const phoneNumber = e.target.value;
    setPhoneNum(e.target.value);
    handleFullNumberChange(dialCode, phoneNumber);
  }, [dialCode, handleFullNumberChange]);

  return (
    <TextInputContainer style={style}>
      <DialCodeDropDown
        disabled={disabled}
        handleChange={onDialCodeChange}
        value={dialCode}
        locale={locale}
        activeMode={activeMode}
        themeColor={themeColor}
      />
      <StyledTextField
        {...props}
        type="tel"
        value={phoneNum}
        onChange={onPhoneNumChange}
        themeColor={themeColor}
        activeMode={activeMode}
        variant="outlined"
        error={error || false}
        onBlur={forceComponentAttrMutation}
      />
    </TextInputContainer>
  );
};

PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  updateComponentAttr: PropTypes.func,
  themeColor: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  activeMode: PropTypes.bool.isRequired,
  forceComponentAttrMutation: PropTypes.func,
  targetObjectId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  userLocation: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
};

PhoneInput.defaultProps = {
  placeholder: '',
};

PhoneInput.defaultProps = {
  forceComponentAttrMutation: () => {},
  handleChange: null,
  updateComponentAttr: null,
  style: null,
  required: false,
};

const mapState = (state) => ({
  userLocation: state.userLocation,
});

export default connect(mapState)(PhoneInput);
