import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const isBuiltInPaymentEnabled = ({
  defaultTicket: {
    price: { amount },
  },
  includeTicketing,
  enablePaymentSplit,
}: {
  defaultTicket: {
    price: {
      amount: string;
    };
  };
  includeTicketing: boolean;
  enablePaymentSplit: boolean;
}): boolean => enablePaymentSplit && (parseFloat(amount) > 0 || includeTicketing);

const useBuiltInPaymentEnabled = (): boolean => {
  const defaultTicket = useSelector((s) => s.defaultTicket);
  const includeTicketing = useSelector((s) => s.includeTicketing);
  const enablePaymentSplit = useSelector((s) => s.featureGuard.regformV4Payment);

  const enableBuiltInPayment = isBuiltInPaymentEnabled({
    defaultTicket,
    includeTicketing,
    enablePaymentSplit,
  });

  return useMemo(() => enableBuiltInPayment, [enableBuiltInPayment]);
};

export default useBuiltInPaymentEnabled;
