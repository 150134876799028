import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import XtraFlexbox from 'components/XtraFlexbox';
import XtraLoadingOverlay from 'components/XtraLoadingOverlay';
import XtraText from 'components/XtraText';
import { useTranslation } from 'react-i18next';
import FormValueType from 'schema/formValueType';
import FileIcon from './FileIcon';

const FileUploadDropZoneContainer = styled.div`
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  margin-top: 16px;
`;

const DropzoneContainer = styled.div`
  padding: 24px;
  position: relative;

  :focus {
    outline-width: 0;
  }
`;

const DashedContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 2px dashed #DBDEE2;
  cursor: pointer;
`;

const FileUploadDetailsContainer = styled(XtraFlexbox)`
  width: 100%;
`;

const DragHereText = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #484848;
  display: block;
  margin: 40px auto 8px;
`;

const SelectFileText = styled.span`
  color: #484848;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 22px;
  text-align: center;
  display: block;
`;

const ImageErrorText = styled(SelectFileText)`
  color: red;
`;

const FileNameContainer = styled.div`
  width: 100%;
  padding: 8px 24px;
`;

const FileName = styled(XtraText)`
  display: inline-block;
`;

const RemoveFileText = styled(FileName)`
  color: ${({ themeColor }) => (themeColor)};
  padding-left: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledXtraLoadingOverlay = styled(XtraLoadingOverlay)`
  position: relative;
`;

const FileIconContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

const AllowedFileTypes = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'text/plain',
  'text/csv',
];

const MAX_FILE_SIZE_MB = 25;

const FileUploadDropZone = ({
  attendeeId,
  targetObjectId,
  questionAnswer,
  handleChange,
  updateComponentAttr,
  disabled,
  themeColor,
  locale,
  increaseCurrentUploading,
  reduceCurrentUploading,
}) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const onDrop = (acceptedFiles) => {
    setIsUploading(true);
    setErrorMessage(false);
    if (acceptedFiles.length > 0) {
      increaseCurrentUploading();
      handleChange({
        targetObjectId,
        attendeeId,
        attrName: 'questionAnswer',
        valueType: FormValueType.FILE,
        value: acceptedFiles[0],
        localValue: { uploading: true },
        callback: (success, res) => {
          if (success) {
            const {
              value: {
                files,
              },
            } = res.affectedElements[0];
            updateComponentAttr({
              targetObjectId,
              attendeeId,
              attrName: 'questionAnswer',
              value: files[0],
            });
            reduceCurrentUploading();
          } else {
            setErrorMessage(true);
          }
          setIsUploading(false);
        },
      });
    } else {
      setErrorMessage(true);
    }
    setIsUploading(false);
  };
  const removeFile = (e) => {
    e.stopPropagation();
    setIsRemoving(true);
    increaseCurrentUploading();
    handleChange({
      targetObjectId,
      attendeeId,
      attrName: 'questionAnswer',
      valueType: FormValueType.FILE,
      value: null,
      localValue: { removing: true },
      callback: (success) => {
        if (success) {
          updateComponentAttr({
            targetObjectId,
            attendeeId,
            attrName: 'questionAnswer',
            value: {},
          });
          reduceCurrentUploading();
        }
        setIsRemoving(false);
      },
    });
  };
  return (
    <FileUploadDropZoneContainer>
      {Object.keys(questionAnswer).length === 0 ? (
        <Dropzone
          accept={AllowedFileTypes}
          disabled={disabled}
          maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
          onDropAccepted={(acceptedFiles) => onDrop(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <DropzoneContainer
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <DashedContainer>
                <FileUploadDetailsContainer
                  justifyContent="center"
                  direction="column"
                >
                  <DragHereText>{t('regform_v3_builder.drop_file', { locale: locale.emsMapping })}</DragHereText>
                  <SelectFileText>{t('regform_v3_builder.select_file', { locale: locale.emsMapping })}</SelectFileText>
                  <SelectFileText style={{ margin: '40px auto' }}>
                    {`*${t('regform_v3_builder.size', { locale: locale.emsMapping })}：≤${MAX_FILE_SIZE_MB}MB`}
                  </SelectFileText>
                  {errorMessage && (
                    <ImageErrorText>
                      {t('regform_v3_builder.file_upload.error', { locale: locale.emsMapping })}
                    </ImageErrorText>
                  )}
                </FileUploadDetailsContainer>
              </DashedContainer>
            </DropzoneContainer>
          )}
        </Dropzone>
      ) : (
        <>
          {isRemoving || isUploading ? (
            <StyledXtraLoadingOverlay
              isLoading={isRemoving || isUploading}
              size={32}
              thickness={8}
              color={themeColor}
              loadingMsg={t(`regform_v3_builder.${isRemoving ? 'removing_file' : 'uploading'}`, { locale: locale.emsMapping })}
            />
          ) : (
            <>
              <FileIconContainer>
                <FileIcon fillColor={themeColor} />
              </FileIconContainer>
              <FileNameContainer>
                <FileName>{questionAnswer.filename}</FileName>
                <RemoveFileText themeColor={themeColor} onClick={removeFile}>
                  {t('general.delete', { locale: locale.emsMapping })}
                </RemoveFileText>
              </FileNameContainer>
            </>
          )}
        </>
      )}
    </FileUploadDropZoneContainer>
  );
};

FileUploadDropZone.propTypes = {
  attendeeId: PropTypes.string.isRequired,
  targetObjectId: PropTypes.string.isRequired,
  questionAnswer: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  updateComponentAttr: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  themeColor: PropTypes.string.isRequired,
  locale: PropTypes.shape({
    code: PropTypes.string,
    display: PropTypes.string,
    emsMapping: PropTypes.string,
  }).isRequired,
  increaseCurrentUploading: PropTypes.func,
  reduceCurrentUploading: PropTypes.func,
};

FileUploadDropZone.defaultProps = {
  increaseCurrentUploading: () => {},
  reduceCurrentUploading: () => {},
};

const mapDispatch = ({
  isUploadingFile,
}) => (isUploadingFile ? {
  increaseCurrentUploading: isUploadingFile.increaseCurrentUploading,
  reduceCurrentUploading: isUploadingFile.reduceCurrentUploading,
} : null);

export default connect(null, mapDispatch)(FileUploadDropZone);
