import { useTranslation } from 'react-i18next';
import GenericDropdown from './GenericDropdown';

const IndustryDropDown = ({
  placeholder,
  questionAnswer,
  disabled,
  handleChange,
  industriesOption,
  themeColor,
  locale,
  targetObjectId,
  attendeeId,
  footerColorMode,
}) => {
  const { t } = useTranslation();
  let selectedValue;
  if (questionAnswer !== '' && industriesOption.includes(questionAnswer)) {
    selectedValue = {
      value: questionAnswer,
      label: t(`setup.industry.${questionAnswer}`, { locale: locale.emsMapping === 'ja' || locale.emsMapping === 'ko' ? 'en' : locale.emsMapping }),
    };
  }
  return (
    <GenericDropdown
      value={selectedValue}
      attendeeId={attendeeId}
      targetObjectId={targetObjectId}
      themeColor={themeColor}
      options={industriesOption.map((industry) => ({
        value: industry,
        label: t(`setup.industry.${industry}`, { locale: locale.emsMapping === 'ja' || locale.emsMapping === 'ko' ? 'en' : locale.emsMapping }),
      }))}
      disabled={disabled}
      handleChange={handleChange}
      placeholder={placeholder}
    />
  );
};

export default IndustryDropDown;
