import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import { generate } from '@ant-design/colors';
import GenericDropdown from './GenericDropdown';

const DropDownContainer = styled.div`
  padding: 16px 24px;
`;

const DropDown = ({
  choices,
  questionAnswer,
  disabled,
  handleChange,
  themeColor,
  targetObjectId,
  attendeeId,
  footerColorMode,
}) => {
  let selectedValue = null;
  if (questionAnswer.length > 0) {
    selectedValue = choices.find(
      (eachOption) => eachOption.value === questionAnswer[0],
    );
  }
  return (
    <GenericDropdown
      value={selectedValue}
      isDisabled={disabled}
      options={choices}
      handleChange={(newSelectedOption) => {
        handleChange({
          targetObjectId,
          attendeeId,
          attrName: 'questionAnswer',
          valueType: 'COLLECTION',
          value: [newSelectedOption.value],
        });
      }}
      themeColor={themeColor}
      footerColorMode={footerColorMode}
      style={{ width: '100%' }}
    />
  );
};

DropDown.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  themeColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  questionAnswer: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetObjectId: PropTypes.string.isRequired,
  attendeeId: PropTypes.string.isRequired,
  footerColorMode: PropTypes.string.isRequired,
};

DropDown.defaultProps = {
  handleChange: () => {},
};

export default DropDown;
