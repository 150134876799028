import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

const Message = styled.span`
  color: red;
  transition: all 0.5s;
  z-index: ${({ state }) => (state === 'entered' ? 1000 : 0)};
  opacity: ${({ state }) => (state === 'entered' ? 0.9 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  transform:
    ${({ state, direction }) => {
    if (state === 'entered') {
      return 'translateY(0) translateX(0)';
    }
    switch (direction) {
      case 'up':
        return 'translateY(20px)';
      case 'down':
        return 'translateY(-20px)';
      case 'left':
        return 'translateX(-20px)';
      case 'right':
        return 'translateX(-20px)';
      default:
        return null;
    }
  }};
`;

const ErrorMessage = ({
  errorMessage,
  locale,
  code,
  direction,
}) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState('');
  useEffect(() => {
    if (errorMessage !== '') {
      setErrorText(t(`${errorMessage}`, {
        locale: locale?.emsMapping,
        code,
      }));
    }
  }, [errorMessage, locale]);
  return (
    <Transition in={locale !== null && errorMessage !== ''} timeout={300} mountOnEnter unmountOnExit>
      {(state) => (
        <Message state={state} direction={direction}>
          {errorText}
        </Message>
      )}
    </Transition>
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  locale: PropTypes.shape().isRequired,
  code: PropTypes.string,
  direction: PropTypes.string,
};

ErrorMessage.defaultProps = {
  code: '',
  direction: 'down',
};

export default ErrorMessage;
