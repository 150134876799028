/* eslint-disable react/prop-types */
import FormValueType from 'schema/formValueType';
import { TextFieldProps } from '@material-ui/core';
import { FC } from 'react';
import TextInput from './TextInput';

interface HandleChangeProps {
  attrName: string;
  valueType: FormValueType;
  targetObjectId: string;
  attendeeId: string;
  value: string;
}

interface TextAreaProps {
  themeColor: string;
  activeMode: boolean;
  forceComponentAttrMutation: unknown;
  targetObjectId: string;
  attendeeId: string;
  attrName: string;
  handleChange: (props: HandleChangeProps) => any;
}

const TextAreaInput: FC<TextFieldProps & TextAreaProps> = (props) => (
  <TextInput
    multiline
    inputProps={{
      style: {
        borderWidth: 2,
        borderColor: '#dbdee2',
        borderRadius: 4,
      },
    }}
    rows={4}
    {...props}
  />
);

export default TextAreaInput;
